.pagination {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 2rem;
    margin-bottom: 2.5rem;
}

.pagination li {
    cursor: pointer;
    padding: 0.75rem 1.25rem;
    transition: background 100ms ease-in;
}

.active {
    background: var(--primary-dark);
}

@media screen and (min-width: 600px) {

    .pagination {
        margin-bottom: 5rem;
    }

    .pagination li {
        font-size: 1.25rem;
    }

}