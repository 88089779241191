.nft-landing {
    background: #ddd3c9;
    background-size: cover;
    padding-top: 21.8597%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nft-landing h1 {
    margin: -21.8597% 0 0;
    color: white;
    font-size: 6vw;
    position: relative;
    z-index: 1;
}

.nft-landing h1:before {
    content: '';
    background: url("/src/images/b-about.png") no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 85%;
    z-index: -1;
}

.collection-heading {
    padding: 1.5rem;
    color: var(--text-primary);
}

.collection-heading p {
    font-weight: 500;
}

.search {
    padding: 0 1.5rem;
    display: flex;
    position: relative;
}

.search img {
    position: absolute;
    width: 30px;
    height: auto;
    margin-left: 15px;
    top: 14px;
}

.search input {
    outline: none;
    border: 2px solid #e6e6e6;
    border-radius: 5px;
    width: 100%;
    padding: 1rem 1rem 1rem 3.5rem;
    font-size: 1rem;
    font-family: Montserrat, sans-serif;
    color: var(--text-primary);
    font-weight: 600;
}

.assets {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    place-items: center;
    gap: 2rem;
    margin-block: 2.5rem;
    padding: 0 1.5rem;
}

@media screen and (max-width: 320px) {
    .assets {
        grid-template-columns: 1fr;
    }
}

.assets .asset {
    box-shadow: 0 5px 20px 5px rgba(211, 211, 211, 0.3);
}

.assets .asset a {
    text-decoration: none;
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.assets .asset a img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}

.assets .asset a .asset-info {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}

.assets .asset a .asset-info .left {
    text-align: start;
}

.assets .asset a .asset-info .right {
    text-align: end;
}

.assets .asset a .asset-info .collection-name, .assets .asset a .asset-info .price{
    font-weight: 500;
    position: relative;
}

.assets .asset a .asset-info .name, .assets .asset a .asset-info .price-label {
    font-weight: 700;
}

.assets .asset a .asset-info .price-label {
    margin-right: 5px;
}

.assets .asset a .asset-info .price:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 25px;
    background: url("/src/images/eth.png") no-repeat;
    background-size: contain;
    top: -4px;
    left: -10px;
}

.pagination {
    justify-content: center;
}

@media screen and (min-width: 769px) {

    .nft-landing h1 {
        line-height: 5rem;
    }

    .nft-landing h1:before {
        top: 120%;
    }

    .collection-heading {
        margin-top: 1rem;
        width: 75%;
        margin-inline: auto;
    }

    .collection-heading h1 {
        font-size: 2.75rem;
    }

    .collection-heading p {
        font-size: 1.25rem;
        font-weight: 500;
    }

    .search {
        padding: 0 10rem;
    }

    .search img {
        width: 35px;
        margin-left: 15px;
        top: 10px;
    }

    .search input {
        padding-left: 4rem;
    }

    .assets {
        margin-block: 5rem;
        gap: 5rem;
        padding: 0 10em;
    }

    .pagination {
        justify-content: flex-start;
        margin-left: 10rem;
    }

}