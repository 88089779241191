@import url("https://use.typekit.net/jho4wbb.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&display=swap');

:root {
  --primary: #EAE0D5;
  --primary-dark: #c3b6ae;
  --text-primary: #2f2f2f;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
