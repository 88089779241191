/*Header*/

.header {
    background-color: var(--primary);
}

.header-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-title {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.header-logo {
    width: 10rem;
    padding: 1rem;
}

.hamburger {
    cursor: pointer;
    margin-right: 0.5rem;
}

.nav-links-mobile {
    display: flex;
    flex-direction: column;
}

.nav-links a, .nav-links-mobile a {
    text-decoration: none;
    color: inherit;
}

.nav-links-mobile a {
    padding: 1rem;
}

.nav-links {
    display: none;
}

.current-route {
    font-weight: 500;
}

.hidden {
    display: none;
}

@media screen and (min-width: 600px) {

    .header-body {
        justify-content: space-around;
    }

    .header-logo {
        width: 15rem;
    }

    .nav-links {
        display: block;
    }

    .nav-links a {
        padding: 1rem;
    }

    .hamburger {
        display: none;
    }

}