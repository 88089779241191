.App {
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Acherus Grotesque", sans-serif;
  line-height: 3rem;
}

button {
  cursor: pointer;
}