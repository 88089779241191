.affiliate-landing {
    background: #ddd3c9;
    background-size: cover;
    padding-top: 21.8597%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.affiliate-landing h1 {
    margin: -21.8597% 0 0;
    color: white;
    font-size: 6vw;
    position: relative;
    z-index: 1;
}

.affiliate-landing h1:before {
    content: '';
    background: url("/src/images/b-about.png") no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    z-index: -1;
}

.affiliate-links {
    background-color: #f9f9f9;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
}

.affiliate-links .aff-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.affiliate-links .aff-link img {
    width: 10rem;
}

.affiliate-links .aff-link p {
    font-weight: 500;
    font-size: 1rem;
}

.affiliate-links .aff-link button {
    font-family: Montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    border: 0;
    background-color: var(--primary-dark);
    padding: 0.25rem 1.5rem;
    color: black;
    white-space: nowrap;
}

@media screen and (min-width: 821px) {

    .affiliate-landing h1 {
        line-height: 5rem;
    }

    .affiliate-landing h1:before {
        top: 140%;
    }

    .affiliate-links {
        padding: 10rem 10vw;
    }

    .affiliate-links .aff-link {
        flex-direction: row;
        justify-content: space-between;
        column-gap: 1rem;
    }

    .affiliate-links .aff-link img {
        width: 15rem;
    }

    .affiliate-links .aff-link p {
        font-size: 1rem;
        width: 40%;
        text-align: start;
    }

    .affiliate-links .aff-link button {
        font-size: 1.5rem;
        padding: 0.75rem 3.5rem;
    }

}