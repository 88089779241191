/*Ticker*/

.ticker {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    background-color: var(--primary);
    --coin_name_visible: none;
}

.ticker-coin {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.ticker-coin:hover {
    --coin_name_visible: block;
}

.ticker-coin img {
    width: 25%;
    -webkit-user-drag: none;
    user-select: none;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.coin-name {
    user-select: none;
    display: var(--coin_name_visible);
}

@media screen and (min-width: 600px) {
    .coin-name {
        display: block;
    }
}