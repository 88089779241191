.shop-landing {
    background: #ddd3c9;
    background-size: cover;
    padding-top: 21.8597%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shop-landing h1 {
    margin: -21.8597% 0 0;
    color: white;
    font-size: 6vw;
    position: relative;
    z-index: 1;
}

.shop-landing h1:before {
    content: '';
    background: url("/src/images/b-about.png") no-repeat;
    background-size: contain;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 80%;
    left: 25%;
    z-index: -1;
}

.book-info {
    padding: 1rem;
}

.book-section {
    text-align: start;
    background: #f9f9f9;
}

.book-section .book-info h1 {
    color: var(--text-primary);
    position: relative;
    width: fit-content;
    margin-bottom: 5rem;
    margin-inline: auto;
    z-index: 0;
}

.book-section h1:before {
    content: '';
    background: url("/src/images/b9.png") no-repeat;
    background-size: contain;
    width: 70%;
    height: 100%;
    position: absolute;
    top: 90%;
    left: 15%;
    z-index: -1;
}

.book-section .book-synopsis {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}

.book-section .description-title {
    font-size: 1.2rem;
    color: #4c4c4c;
}

hr {
    display: none;
}

.book-image-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 2.5rem;
}

.book-image-link img {
    max-width: 80%;
}

.book-image-link .book-links {
    display: flex;
    column-gap: 1rem;
    width: 100%;
}

.book-image-link .book-links > * {
    flex: 1;
}

.book-image-link a {
    margin-top: 2.5rem;
    text-decoration: none;
}

.book-image-link a button {
    width: 100%;
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    border: 0;
    background-color: var(--primary-dark);
    padding: 0.75rem;
    color: black;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.book-image-link a button img {
    width: 2rem;
}

.book-image-link .gallery {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.book-image-link .gallery img {
    max-width: 15%;
    object-fit: contain;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.5s;
}

.book-image-link .gallery img.selected, .book-image-link .gallery img:hover {
    opacity: 1;
}


.desktop {
    display: none;
}

@media screen and (min-width: 769px) {

    .shop-landing h1 {
        line-height: 5rem;
    }

    .shop-landing h1:before {
        top: 125%;
        left: 28%;
    }
    .book-section .book-info h1 {
        line-height: 5rem;
    }

    .book-section {
        display: flex;
        padding: 5vw;
        column-gap: 5vw;
    }

    .book-section .book-info h1 {
        font-size: 3.5vw;
        margin-inline: revert;
        white-space: nowrap;
    }

    .book-section h1:before {
        width: 100%;
    }

    hr {
        display: block;
    }

    .book-section .book-synopsis {
        text-align: start;
        font-size: 1.5rem;
        width: 85%;
    }

    .book-section .description-title {
        font-size: 1.5rem;
    }

    .book-section .description {
        font-size: 1.25rem;
        margin-bottom: 2.5rem;
    }

    .book-image-link {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /*.book-image-link img {*/
    /*    width: 35vw;*/
    /*}*/

    .book-image-link a {
        margin-top: 4rem;
    }

    .book-image-link a button {
        font-size: 1.25rem;
    }

    .book-image-link a button img {
        width: 2.5rem;
    }

    .mobile {
        display: none;
    }
}