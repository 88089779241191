.about-landing {
    background: #ddd3c9;
    background-size: cover;
    padding-top: 21.8597%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-landing h1 {
    margin: -21.8597% 0 0;
    color: white;
    font-size: 6vw;
    position: relative;
    z-index: 1;
}

.about-landing h1:before {
    content: '';
    background: url("/src/images/b-about.png") no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    z-index: -1;
}

.about-1 {
    background-color: #f9f9f9;
    padding-top: 3rem;
}

.about-1 h1 {
    font-size: 6vw;
    color: var(--text-primary);
    width: min-content;
    margin: auto;
    position: relative;
    z-index: 1;
}

.about-1 h1:before {
    content: '';
    width: 100%;
    height: 100%;
    background: url("/src/images/b3-new.png") no-repeat;
    background-size: contain;
    position: absolute;
    top: 120%;
}

.about-1 .about-info {
    text-align: start;
    padding: 5vw;
    margin-top: 5rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    place-items: center;
    position: relative;
    z-index: 0;
}

.about-1 .about-info:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: url("/src/images/ab1.png") no-repeat;
    background-size: contain;
    z-index: -1;
    top: -10%;
}

.about-1 .about-info p {
    font-weight: 500;
    font-size: 1rem;
}

.about-1 .about-info .about-board-1 {
    order: -1;
    max-width: 60%;
}

.about-1 .about-info .about-book {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-1 .about-info .about-board-2 {
    width: 75%;
}

.about-1 .about-info .about-book a {
    margin-top: 1rem;
    text-decoration: none;
}

.about-1 .about-info .about-book button {
    width: 100%;
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    border: 0;
    background-color: var(--primary-dark);
    padding: 0.75rem;
    color: black;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.about-1 .about-info .about-book button img {
    width: 2rem;
    margin-left: 0.5rem;
}

.about-2 {
    background-color: #f9f9f9;
    padding-top: 3rem;
    padding-bottom: 5rem;
}

/*.about-2 .wrapper {*/
/*    background: url("/src/images/ab2.png") no-repeat 0 100%;*/
/*    background-size: 40%;*/
/*}*/

.about-2 .wrapper p {
    font-weight: 500;
    font-size: 1rem;
    padding: 5vw;
    margin: 2rem auto auto;
}

@media screen and (min-width: 769px) {

    .about-landing h1 {
        line-height: 5rem;
    }

    .about-landing h1:before {
        top: 140%;
    }

    .about-1 {
        padding-top: 5rem;
    }

    .about-1 h1 {
        font-size: 3rem;
    }

    .about-1 .about-info {
        grid-template-columns: 1fr 1fr;
    }

    .about-1 .about-info:before {
        top: -12vh;
    }

    .about-1 .about-info p {
        font-size: 1.5rem;
    }

    .about-1 .about-info .about-board-1 {
        max-width: 50%;
        order: unset;
    }

    .about-1 .about-info .about-board-2 {
        max-width: 60%;
    }

    /*.about-2 .wrapper {*/
    /*    background: url("/src/images/ab2.png") no-repeat;*/
    /*    background-size: contain;*/
    /*}*/

    .about-2 .wrapper p {
        width: 75%;
        margin: auto;
        font-size: 1.5rem;
    }

}