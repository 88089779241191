/*Home*/

.landing-section-1 {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    /* background: linear-gradient(180deg, var(--primary) 40%, #f8f9fa 40%); */
    background: linear-gradient(180deg, var(--primary) 0%, rgba(255,255,255,1) 100%, rgba(0,212,255,1) 100%);
}

.landing-section-1 .landing-image img {
    width: 90vw;
}

.landing-section-1 .landing-text {
    padding: 0 1rem;
}

.landing-section-1 .landing-text h1 {
    color: var(--text-primary);
    position: relative;
    z-index: 0;
}

.landing-section-1 .landing-text h1 span {
    position: relative;
}

.landing-section-1 .landing-text h1 span:after {
    content: '';
    background: url("/src/images/b1-new.png") no-repeat;
    background-size: contain;
    width: 150%;
    height: 50%;
    position: absolute;
    top: 100%;
    left: -20%;
    z-index: -1;
}

.landing-section-1 .landing-text p {
    font-weight: 500;
    position: relative;
    z-index: 0;
}

.subscribe {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscribe input {
    font-family: Montserrat, sans-serif;
    font-size: 0.75rem;
    border: var(--primary) 2px solid;
    border-right: none;
    border-radius: 0;
    padding: 1rem;
    width: 35%;
}

.subscribe input:focus {
    outline: none;
}

.subscribe button {
    font-family: Montserrat, sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    border: var(--primary-dark) 2px solid;
    border-left: none;
    background-color: var(--primary-dark);
    padding: 1rem;
}

.subscribe a img {
    width: 2.5rem;
}

.subscribe a {
    margin-left: 1.5rem;
    width: 2.5rem;
}

.landing-section-2 {
    padding: 2rem 0;
    background: #f8f9fa;
}


.landing-section-2 h1 {
    color: var(--text-primary);
    font-size: 1.5rem;
    position: relative;
    z-index: 0;
    width: fit-content;
    margin: auto;
}

.landing-section-2 h1:before {
    content: '';
    background: url("/src/images/b2-new.png") no-repeat;
    background-size: contain;
    width: 40%;
    height: 45%;
    position: absolute;
    top: 90%;
    z-index: -1;
}

.aims {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
}

.aim {
    padding: 1rem;
}

.aim h2 {
    color: var(--text-primary);
    font-size: 1.5rem;
}

.aim img {
    width: 40%;
}

.aim p {
    font-weight: 500;
}

.landing-section-2 button, .follow button, .story-section .story-text button {
    font-family: Montserrat, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    border: 0;
    background-color: var(--primary-dark);
    padding: 0.5rem 2.5rem;
    color: black;
}

.landing-section-3 {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /*background: linear-gradient(180deg, white 75%, var(--primary) 75%);*/
}

.landing3-text h1 {
    color: var(--text-primary);
    position: relative;
    z-index: 0;
    margin-bottom: 3rem;
}

.landing3-text h1 span {
    position: relative;
}

.landing3-text h1 span:after {
    content: '';
    background: url("/src/images/b3-new.png") no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 0;
}

.landing3-text p {
    font-weight: 500;
}

.landing-section-3 .landing-image img {
    width: 80vw;
}

.follow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.follow a {
    margin-left: 1.5rem;
    width: 2.5rem;
}

.follow a img {
    width: 2.5rem;
}

.landing-section-4 {
    background: #f8f9fa;
    padding: 1rem 0;
}

.landing-section-4 h1 {
    color: var(--text-primary);
    font-size: 1.5rem;
    margin-top: 0;
    position: relative;
    z-index: 0;
}

.landing-section-4 h1 span {
    position: relative;
}

.landing-section-4 h1 span:after {
    content: '';
    background: url("/src/images/b3-new.png") no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
}

.story-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.story-section .story-text {
    order: 2;
    position: relative;
    text-align: start;
    padding: 1rem 1rem 1rem 4rem;
}

.story-section .story-text .band {
    width: 3rem;
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 0;
}

.story-section .story-text p {
    font-weight: 500;
    position: relative;
    z-index: 1;
    padding-left: 1rem;
}

.story-section .story-text button {
    font-size: 1.25rem;
}

.story-section .board {
    max-width: 90vw;
    order: 1;
}

@media screen and (min-width: 769px) {

    .landing-section-1 {
        flex-direction: row;
        justify-content: center;
        /* background: linear-gradient(180deg, var(--primary) 60%, #f8f9fa 40%); */
        background: linear-gradient(180deg, var(--primary) 0%, rgba(255,255,255,1) 100%, rgba(0,212,255,1) 100%);
    }

    .landing-section-1 .landing-image {
        flex-shrink: 0;
        width: 45%;
    }

    .landing-section-1 .landing-image img  {
        width: 80%;
    }

    .landing-section-1 .landing-text {
        padding-right: 3rem;
    }

    .landing-section-1 .landing-text h1 {
        text-align: start;
        font-size: 3rem;
    }

    .landing-section-1 .landing-text p {
        padding-top: 2.5rem;
        text-align: start;
        font-size: 1.75rem;
    }

    .subscribe {
        padding-top: 5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .subscribe input {
        font-size: 1rem;
        border: var(--primary) 2px solid;
        border-right: none;
    }

    .subscribe button {
        font-size: 1rem;
        border: var(--primary-dark) 2px solid;
        border-left: none;
    }

    .subscribe a img {
        width: 3rem;
    }

    .subscribe a {
        margin-left: 2rem;
        width: 3rem;
    }

    .landing-section-2 {
        padding: 4rem 0;
    }

    .landing-section-2 h1 {
        color: var(--text-primary);
        font-size: 3rem;
    }

    .landing-section-2 h1:before {
        top: 110%;
    }

    .aims {
        padding: 4rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .aim {
        width: 20%;
    }
    .aim h2 {
        color: var(--text-primary);
        font-size: 2rem;
    }

    .landing-section-2 button, .follow button {
        padding: 1rem 8rem;
    }

    .landing-section-3 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1rem 1rem 0 0;
        background: linear-gradient(180deg, white 50%, var(--primary) 50%);
    }

    .landing3-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .landing3-text h1 {
        font-size: 3rem;
    }

    .landing3-text p {
        font-weight: 500;
        font-size: 1.5rem;
        text-align: start;
        padding-right: 1rem;
    }

    .landing-section-3 .landing-image {
        width: 50%;
        flex-shrink: 0;
        background: linear-gradient(to right, white 80%, rgba(0,0,0,0) 80%);
    }

    .landing-section-3 .landing-image img {
        width: 75%;
    }

    .follow {
        padding-top: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .follow a img {
        width: 3rem;
    }

    .follow a {
        width: 3rem;
    }

    .landing-section-4 {
        padding-top: 3rem;
    }

    .landing-section-4 h1 {
        font-size: 3rem;
    }

    .landing-section-4 h1 span:after {

    }

    .story-section {
        flex-direction: row;
    }

    .story-section .story-text .band {
        height: 75%;
    }

    .story-section .story-text {
        order: unset;
        display: flex;
        padding-left: 0;
    }

    .story-section .story-text .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 75%;
        padding-left: 5rem;
    }

    .story-section .story-text p {
        text-align: start;
        font-size: 1.5rem;
        padding-left: 0;
    }

    .story-section .story-text button {
        font-size: 1.5rem;
    }

    .story-section .board {
        padding-right: 2rem;
        flex-shrink: 0;
        flex-basis: 0;
        max-width: 35%;
        height: 100%;
        order: unset;
    }

}