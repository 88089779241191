/*News*/

.news-landing {
    background: #ddd3c9;
    background-size: cover;
    padding-top: 21.807%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-landing h1 {
    margin: -21.807% 0 0;
    color: white;
    font-size: 6vw;
    position: relative;
    z-index: 1;
}

.news-landing h1:before {
    content: '';
    background: url("/src/images/b-about.png") no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 85%;
    z-index: -1;
}

.feed {
    margin-bottom: 2.5rem;
}

.article {
    display: flex;
    margin: 1rem;
    align-items: center;
}

.article .thumbnail {
    flex-shrink: 0;
    object-fit: cover;
    width: 35vw;
    height: 12vh;
}

.article .sep {
    height: 85px;
    padding-left: 0.25rem;
}

.article-body {
    padding-left: 1rem;
}

.article-body a {
    text-align: start;
    text-decoration: none;
    color: var(--text-primary);
    font-size: 0.75rem;
}
.article-body a h5 {
    line-height: unset;
}

.article-body p {
    text-align: start;
    font-size: 0.5rem;
}

.pagination {
    justify-content: center;
}


@media screen and (min-width: 1025px) {

    .news-landing h1 {
        line-height: 5rem;
    }

    .news-landing h1:before {
        top: 120%;
    }

    .feed {
        padding: 0 10rem;
        margin-bottom: 5rem;
    }

    .article {
        padding: 1.5rem 0;
        margin-inline: 0;
        align-items: center;
    }

    .article .thumbnail {
        flex-shrink: 0;
        object-fit: cover;
        width: 400px;
        height: 300px;
    }

    .article .sep {
        height: 285px;
        padding-left: 2rem;
    }

    .article-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 5rem;
    }

    .article-body a {
        font-size: 2rem;
    }

    .article-body p {
        font-size: 1.25rem;
    }

    .pagination {
        justify-content: flex-start;
        margin-left: 10rem;
    }

}