@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-MediumItalic.eot');
    src: local('Acherus Grotesque Medium Italic'), local('AcherusGrotesque-MediumItalic'),
        url('AcherusGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-MediumItalic.woff2') format('woff2'),
        url('AcherusGrotesque-MediumItalic.woff') format('woff'),
        url('AcherusGrotesque-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-Thin.eot');
    src: local('Acherus Grotesque Thin'), local('AcherusGrotesque-Thin'),
        url('AcherusGrotesque-Thin.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-Thin.woff2') format('woff2'),
        url('AcherusGrotesque-Thin.woff') format('woff'),
        url('AcherusGrotesque-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-RegularItalic.eot');
    src: local('Acherus Grotesque Regular Italic'), local('AcherusGrotesque-RegularItalic'),
        url('AcherusGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-RegularItalic.woff2') format('woff2'),
        url('AcherusGrotesque-RegularItalic.woff') format('woff'),
        url('AcherusGrotesque-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-Medium.eot');
    src: local('Acherus Grotesque Medium'), local('AcherusGrotesque-Medium'),
        url('AcherusGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-Medium.woff2') format('woff2'),
        url('AcherusGrotesque-Medium.woff') format('woff'),
        url('AcherusGrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-ThinItalic.eot');
    src: local('Acherus Grotesque Thin Italic'), local('AcherusGrotesque-ThinItalic'),
        url('AcherusGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-ThinItalic.woff2') format('woff2'),
        url('AcherusGrotesque-ThinItalic.woff') format('woff'),
        url('AcherusGrotesque-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-UltraLight.eot');
    src: local('Acherus Grotesque UltraLight'), local('AcherusGrotesque-UltraLight'),
        url('AcherusGrotesque-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-UltraLight.woff2') format('woff2'),
        url('AcherusGrotesque-UltraLight.woff') format('woff'),
        url('AcherusGrotesque-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-BlackItalic.eot');
    src: local('Acherus Grotesque Black Italic'), local('AcherusGrotesque-BlackItalic'),
        url('AcherusGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-BlackItalic.woff2') format('woff2'),
        url('AcherusGrotesque-BlackItalic.woff') format('woff'),
        url('AcherusGrotesque-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-Regular.eot');
    src: local('Acherus Grotesque Regular'), local('AcherusGrotesque-Regular'),
        url('AcherusGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-Regular.woff2') format('woff2'),
        url('AcherusGrotesque-Regular.woff') format('woff'),
        url('AcherusGrotesque-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-ExtraBold.eot');
    src: local('Acherus Grotesque ExtraBold'), local('AcherusGrotesque-ExtraBold'),
        url('AcherusGrotesque-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-ExtraBold.woff2') format('woff2'),
        url('AcherusGrotesque-ExtraBold.woff') format('woff'),
        url('AcherusGrotesque-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-BoldItalic.eot');
    src: local('Acherus Grotesque Bold Italic'), local('AcherusGrotesque-BoldItalic'),
        url('AcherusGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-BoldItalic.woff2') format('woff2'),
        url('AcherusGrotesque-BoldItalic.woff') format('woff'),
        url('AcherusGrotesque-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-LightItalic.eot');
    src: local('Acherus Grotesque Light Italic'), local('AcherusGrotesque-LightItalic'),
        url('AcherusGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-LightItalic.woff2') format('woff2'),
        url('AcherusGrotesque-LightItalic.woff') format('woff'),
        url('AcherusGrotesque-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-Black.eot');
    src: local('Acherus Grotesque Black'), local('AcherusGrotesque-Black'),
        url('AcherusGrotesque-Black.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-Black.woff2') format('woff2'),
        url('AcherusGrotesque-Black.woff') format('woff'),
        url('AcherusGrotesque-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-UltraLightIt.eot');
    src: local('Acherus Grotesque UltraLight Italic'), local('AcherusGrotesque-UltraLightIt'),
        url('AcherusGrotesque-UltraLightIt.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-UltraLightIt.woff2') format('woff2'),
        url('AcherusGrotesque-UltraLightIt.woff') format('woff'),
        url('AcherusGrotesque-UltraLightIt.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-ExtraBoldIt.eot');
    src: local('Acherus Grotesque ExtraBold Italic'), local('AcherusGrotesque-ExtraBoldIt'),
        url('AcherusGrotesque-ExtraBoldIt.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-ExtraBoldIt.woff2') format('woff2'),
        url('AcherusGrotesque-ExtraBoldIt.woff') format('woff'),
        url('AcherusGrotesque-ExtraBoldIt.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-Bold.eot');
    src: local('Acherus Grotesque Bold'), local('AcherusGrotesque-Bold'),
        url('AcherusGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-Bold.woff2') format('woff2'),
        url('AcherusGrotesque-Bold.woff') format('woff'),
        url('AcherusGrotesque-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-Light.eot');
    src: local('Acherus Grotesque Light'), local('AcherusGrotesque-Light'),
        url('AcherusGrotesque-Light.eot?#iefix') format('embedded-opentype'),
        url('AcherusGrotesque-Light.woff2') format('woff2'),
        url('AcherusGrotesque-Light.woff') format('woff'),
        url('AcherusGrotesque-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

