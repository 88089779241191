/*Footer*/

.footer-body {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer-body .logo {
    height: 7.75rem;
}

.footer-nav-links-2 {
    padding: 1rem 0.75rem 2.25rem;
    display: flex;
    justify-content: space-between;
}

.footer-nav-links-2 a {
    text-decoration: none;
    color: inherit;
    font-size: 0.75rem;
}

.socials {
    display: flex;
    align-items: center;
    column-gap: 2.25rem;
}

.socials a {
    flex-shrink: 0;
}

.socials a img {
    height: 2.5rem;
}

.email a {
    color: var(--text-primary);
    font-weight: 500;
    font-size: 0.75rem;
}


@media screen and (min-width: 600px) {

    .footer-body .logo {
        height: 10rem;
    }

    .footer-nav-links-2 {
        display: none;
    }

    .socials {
        display: flex;
        align-items: center;
    }

    .socials a {
        flex-shrink: 0;
    }

    .socials a img {
        height: 3rem;
    }

    .email {
        margin-top: -2rem;
        margin-bottom: 2rem;
    }

    .email a {
        color: var(--text-primary);
        font-weight: 500;
        font-size: 1rem;
    }

}